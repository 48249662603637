<template>
  <div>
    <b-card-actions
      ref="cardA"
      title="Authorization Token"
      action-collapse
      no-body
    >
      <b-card class="mb-2" no-body>
        <div class="mx-2">
          <b-row>
            <b-col cols="12" md="12" xl="8" offset-xl="2">
              <HeroInputText
                id="auth-code"
                v-model="authCode"
                label="Authorization Code"
                column="3"
                :readonly="true"
                custom-style="background-color: white; text-align: center;"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12" xl="8" offset-xl="2">
              <HeroInputText
                id="access-token"
                v-model="accessToken"
                label="Access Token"
                column="3"
                :readonly="true"
                custom-style="background-color: white; text-align: center;"
              />
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-card-actions>

    <b-card-actions
      ref="cardB"
      title="Subscriptions"
      action-collapse
      no-body
    >
      <b-card class="mb-2" no-body>
        <div class="mx-2">
          <b-row>
            <b-col cols="12">
              <b-table-simple v-if="results.length > 0" responsive bordered class="text-center" style="font-weight: normal; font-size: 12px; white-space:nowrap;">
                <b-thead>
                  <b-tr>
                    <b-th class="smaller">
                      Status
                    </b-th>
                    <b-th class="smaller">
                      Advertiser Id
                    </b-th>
                    <b-th class="smaller">
                      TikTok Code
                    </b-th>
                    <b-th class="smaller">
                      TikTok Message
                    </b-th>
                    <b-th class="smaller">
                      TikTok Subscription Id
                    </b-th>
                  </b-tr>
                </b-thead>

                <b-tbody>
                  <template v-for="result in results">
                    <b-tr :key="result.id">
                      <!-- Status -->
                      <b-td>
                        <template v-if="result.subscription_status === 1">
                          <feather-icon
                            icon="CheckIcon"
                            class="mr-50"
                          />
                          <span>Success</span>
                        </template>

                        <template v-if="result.subscription_status === 0">
                          <feather-icon
                            icon="FrownIcon"
                            class="mr-50"
                          />
                          <span>Fail</span>
                        </template>
                      </b-td>

                      <b-td style="text-align: left;">
                        {{ result.advertiser_id }}
                      </b-td>

                      <b-td style="text-align: left;">
                        {{ result.tiktok_code }}
                      </b-td>

                      <b-td style="text-align: left;">
                        {{ result.tiktok_message }}
                      </b-td>

                      <b-td style="text-align: left;">
                        {{ result.tiktok_subscription_id }}
                      </b-td>
                    </b-tr>
                  </template>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-card-actions>
  </div>
</template>

<script>
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import SweetAlert from '@/services/SweetAlert'
import Toastification from '@/services/Toastification'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardActions,
    HeroInputText,
  },
  data() {
    return {
      authCode: null,
      accessToken: null,
      advertiserIds: [],
      results: [],
    }
  },
  async mounted() {
    this.authCode = this.getAuthCode()

    await this.getAccessToken()

    if (this.accessToken) {
      await this.subscribe()
    }
  },
  methods: {
    getAuthCode() {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      })

      return params.auth_code
    },

    async getAccessToken() {
      this.$refs.cardA.showLoading = true

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)

      try {
        const payload = {
          auth_code: this.authCode,
        }

        const url = 'tiktok/get-access-token'
        const response = await axios.post(url, payload, axiosConfig)

        this.accessToken = response.data.access_token
        this.advertiserIds = response.data.advertiser_ids
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
      }

      this.$refs.cardA.showLoading = false
    },

    async subscribe() {
      this.$refs.cardB.showLoading = true

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)

      try {
        const payload = {
          auth_code: this.authCode,
          access_token: this.accessToken,
        }

        const url = 'tiktok/subscribe'
        const response = await axios.post(url, payload, axiosConfig)

        this.results = response.data.results

        if (response.data.status === 'success') {
          const toastObject = Toastification.getContentSuccess(`${response.data.message}`)
          this.$toast(toastObject)
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
      }

      this.$refs.cardB.showLoading = false
    },
  },
}
</script>
